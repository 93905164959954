<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
      >
        <div>
          <td style="vertical-align: middle;">
            <logo
              style="height: 50px; width: auto; margin-top: 0.15rem;"
            />
          </td>
          <td style="vertical-align: middle;">
            <h2
              class="brand-text text-primary"
              style="margin-top: 7px; margin-left: 0.5rem; text-align: center;"
            >
              {{ appName }}
            </h2>
          </td>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            :alt="$t('Verify Email')"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('MessageVerifyEmail.Title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('MessageVerifyEmail.Text') }}
          </b-card-text>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-verify-email-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="verify-email-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="verify-email-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="verify-email-email"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('Confirm') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Logo from '@/@core/layouts/components/AdxLogo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/verify-email-v2.svg'),
      // validation
      required,
      email,
      skin: store.state.appConfig.layout.skin,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/verify-email-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    id() {
      const { id } = this.$route.params
      return id
    },
    token() {
      const { token } = this.$route.params
      return token
    },
  },
  setup() {
    const { appName } = $themeConfig.app

    return {
      appName,
    }
  },
  mounted() {
    localize(this.$i18n.locale)
    this.getEmail()
  },
  methods: {
    getEmail() {
      useJwt.getEmailVerify({
        id: this.id,
        token: this.token,
      })
        .then(response => {
          this.userEmail = response.data.email
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: this.$i18n.t('Are you sure?'),
            text: this.$i18n.t('Proceed to verify your email.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('Yes, verify it'),
            cancelButtonText: this.$i18n.t('Cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value === true) {
              useJwt.verifyEmail({
                id: this.id,
                token: this.token,
              })
                .then(() => {
                  const msg = this.$i18n.t('Success, verify your email')
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: msg,
                      icon: 'InfoIcon',
                      variant: 'success',
                    },
                  })
                  this.$router.push({ name: 'auth-login' })
                })
                .catch(error => {
                  const { response, message } = error
                  if (response) {
                    let errorMsg = this.$i18n.t(response?.data.error)
                    if (errorMsg === '') {
                      errorMsg = this.$i18n.t(response?.data.message)
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: errorMsg,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'bottom-right',
                    })
                  } else if (message) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error?.message,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'bottom-right',
                    })
                  }
                })
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
